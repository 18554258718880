<template>
  <a class="btn btn-secondary btn-sm" href="#" @click="send">{{ name }}<br><br><span>{{ span }}</span></a>
</template>

<script>
import axios from 'axios';

export default {
  name: "Counter",
  props: {
    id: Number,
    uik: Number,
    name: String,
    span: String,
  },
  methods: {
    send() {
      axios.post(`https://el.rostkov.me/vote.php`, {
        uik_id: this.uik,
        table_id: this.id,
      }).then((response) => {
        try {
          const data = response.data;
          if (data.status === 'ok') {
            alert('+1 (' + data.today + ')');
            this.$emit('today', data.today);
            this.$emit('all', data.all);
          } else {
            alert(data.message);
          }
        } catch (e) {
          console.log(e);
          alert('Ошибка при обработке ответа сервера - ' + e);
        }
      }).catch((error) => {
        alert(error);
      });
    },
  },
}
</script>

<style scoped>

a {
  text-decoration: none;
  align-items: center;
  display: flex;
  height: 20%;
  justify-content: center;
}

a:nth-child(odd) {
  background-color: #81c784;
  color: #000000;
}

a:nth-child(even) {
  background-color: #2e7d32;
  color: #ffffff;
}

@media (max-width: @screen-xs) {
  body {
    font-size: 20px;
  }
}

@media (max-width: @screen-sm) {
  body {
    font-size: 28px;
  }
}

a {
  font-size: 2.5rem;
}

span {
  font-size: 2rem;
}
</style>