<template>
  <div id="app">
    <h1>Счётчик</h1>
    <div class="module">
      <counter name="Урна 1" span="" id="1" :uik="uik" @today="today = $event" @all="all = $event"></counter>
      <br>
      <h2>{{ today }} ({{ all }})</h2>
    </div>
  </div>
</template>

<script>
import Counter from './components/Counter.vue'
import axios from "axios";

export default {
  name: 'App',
  components: {
    Counter
  },
  data() {
    return {
      uik: 1,
      today: 0,
      all: 0
    }
  },
  mounted() {
    // get uik from url get params
    const urlParams = new URLSearchParams(window.location.search);
    this.uik = urlParams.get('uik');
    axios.post(`https://el.rostkov.me/vote.php`, {
      uik_id: this.uik,
      table_id: 1,
      info: true,
    }).then((response) => {
      try {
        const data = response.data;
        if (data.status === 'ok') {
          this.today = data.today;
          this.all = data.all;
        } else {
          alert(data.message);
        }
      } catch (e) {
        console.log(e);
        alert('Ошибка при обработке ответа сервера - ' + e);
      }
    }).catch((error) => {
      alert(error);
    });
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.module {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
}
h1{
  font-size: 66px;
}
</style>
